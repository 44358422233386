<template>
  <div class="details">
    <v-banner-box :data="swiperData">
      <template slot="button">
        <p />
      </template>
    </v-banner-box>
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-1.png"
      :img-style="{ transform: 'translate(10px,-110%)' }"
    />
    <div class="details-position">
      <ul class="items">
        <li>
          <router-link :to="'/'">
            首页
          </router-link>
        </li>
        <template v-for="(item, index) in lists">
          <li
            v-if="index != lists.length - 1"
            :key="index"
          >
            <router-link :to="{ path: item.path }">
              {{ item.meta.title }}
            </router-link>
          </li>
        </template>
        <li>{{ detail.title }}</li>
      </ul>
    </div>
    <div class="details-content">
      <h1 class="title">
        {{ detail.title }}
      </h1>
      <div class="info">
        <div class="source">
          文章来源：{{ detail.source }}
        </div>
        <div class="time">
          发布时间：{{ detail.pubDate }}
        </div>
      </div>
      <div
        class="text"
        v-html="detail.content"
      />
    </div>
  </div>
</template>

<script>
// NOTE :  新闻详情

import vBannerBox from '@/components/banner/bannerBox.vue'
export default {
  data () {
    return {
      swiperData: [],
      lists: [],
      detail: {}
    }
  },
  components: {
    vBannerBox
  },
  created () {
    this.getSwiperData()
    this.routeList()
    this.getNewsDetail(this.$route.query.id)
  },
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['新闻中心']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取路由导航
    routeList () {
      const matched = this.$route.matched
      if (matched[0].name === 'main') {
        matched.shift()
      }
      this.lists = matched
    },
    // 获取新闻详情
    getNewsDetail (id) {
      this.$api
        .getNewsDetail(id)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return console.error(res)
          }
          this.detail = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  position: relative;
  &-position {
    padding: 10px 0;
    .items {
      display: flex;
      align-items: center;
      li {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        cursor: pointer;
        &:not(:last-child)::after {
          content: '>';
          display: inline-block;
          margin: 0 13px;
        }
        &:hover,
        &:hover a {
          color: #000;
        }
      }
    }
  }
  &-content {
    width: 100%;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 24px 24px 45px;
    box-sizing: border-box;
    .title {
      font-size: 38px;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      margin-bottom: 14px;
    }
    .info {
      display: flex;
      margin-bottom: 28px;
      .source {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-right: 63px;
      }
      .time {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }
    .text {
      padding: 0 3px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      p {
        text-indent: 2em;
      }
      /deep/ img {
        display: block;
        max-width: 80%;
        margin: 21px auto 19px;
      }
      .img {
        display: block;
        text-align: center;
        color: #1890ff;
        line-height: 16px;
        margin-bottom: 19px;
      }
    }
  }
}
</style>
